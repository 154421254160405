.dx-command-select {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important;
}

/*hide revert button in cell edit*/
.dx-datagrid-revert-tooltip {
  display: none;
}
.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: #e6e6e6;
}
.dx-toolbar-item-content,
.dx-datagrid-export-button,
.dx-button-content,
.dx-icon-export-excel-button,
.dx-toolbar-button {
  margin: 2px !important;
  padding: 0 !important;
}
.dx-scrollbar-vertical.dx-scrollbar-hoverable
  .dx-scrollable-scroll
  .dx-scrollable-scroll-content {
  width: 300px !important;
}
.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: #e6e6e6;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-right: 1px solid #ddd;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td:first-child {
  border-right: none;
}

#gridContainer .dx-scrollable-scroll-content {
  background-color: #acacac !important;
  color: #acacac !important;
}

.dx-select-checkbox {
  min-height: 40px;
  display: flex !important; /*this will only affect check boxes in grid*/
  justify-content: center;
  align-items: center;
}
